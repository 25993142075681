import React from "react";
import "./promo.scss";
import promoimg from "../../Media/PROJECT RENT PROMO.webp";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCheckDouble,
  faClock,
  faDumbbell,
  faMedal,
  faN,
  faSwimmingPool,
  faTv,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp, faYoutube} from "@fortawesome/free-brands-svg-icons";

const Cherrywa = () => {
  const whatsappLink =
    "https://api.whatsapp.com/send/?phone=6285283066969&text=Halo%20Kak,%20Saya%20lihat%20iklan%20diwebsite,%20apa%20ada%20room%20available?%20(Promo)%20https://sewa-apartemen-lagoon.com//&type=phone_number&app_absent=0";
  window.location.href = whatsappLink;
};

const promo = () => {
  return (
    <div id="promo" className="promolagoon">
      <div className="background">
        <div className="judulpromo">Apartemen Lagoon</div>
        <div className="container-promo">
          <div className="promo-gambar">
            <img src={promoimg} alt={promoimg} />
          </div>
          <div className="container-desk">
            <div className="juduldesk">Promo Lagoon</div>
            <div className="promo-desk">
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faMedal} color="rgb(200, 169, 92)" />
                <span style={{color: "grey"}}>&nbsp;&nbsp;Start from 100k</span>
              </div>
              <div id="paketpagi" className="penawaranpoin">
                <FontAwesomeIcon icon={faMedal} color="rgb(200, 169, 92)" />
                <span style={{color: "grey"}}>
                  &nbsp;&nbsp;Paket Pagi 250k (08.00 - 21.00)
                </span>
              </div>
              <div id="paketmalam" className="penawaranpoin">
                <FontAwesomeIcon icon={faMedal} color="rgb(200, 169, 92)" />
                <span style={{color: "grey"}}>
                  &nbsp;&nbsp;Paket Malam 250k (21.00 - 10.00)
                </span>
              </div>
            </div>
            <div className="judulfasilitas">Fasilitas Lagoon</div>
            <div className="fasilitas-desk">
              <div className="fasilitas1">
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faClock} color="rgb(200, 169, 92)" />
                  <span style={{color: "grey"}}>&nbsp;&nbsp;Open 24 Hours</span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faTv} color="rgb(200, 169, 92)" />
                  <span style={{color: "grey"}}>&nbsp;&nbsp;Smart TV</span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faN} color="rgb(200, 169, 92)" />
                  <span style={{color: "grey"}}>&nbsp;&nbsp;Netflix</span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faYoutube} color="rgb(200, 169, 92)" />
                  <span style={{color: "grey"}}>&nbsp;&nbsp;Youtube</span>
                </div>
              </div>
              <div className="fasilitas2">
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faWifi} color="rgb(200, 169, 92)" />
                  <span style={{color: "grey"}}>&nbsp;&nbsp;Wifi</span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon
                    icon={faDumbbell}
                    color="rgb(200, 169, 92)"
                  />
                  <span style={{color: "grey"}}>&nbsp;&nbsp;Gym</span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon
                    icon={faSwimmingPool}
                    color="rgb(200, 169, 92)"
                  />
                  <span style={{color: "grey"}}>&nbsp;&nbsp;Swimming Pool</span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon
                    icon={faBuilding}
                    color="rgb(200, 169, 92)"
                  />
                  <span style={{color: "grey"}}>&nbsp;&nbsp;City View</span>
                </div>
              </div>
            </div>
            <div className="containerwhatsapp">
              <button onClick={Cherrywa} className="whatsappsumban">
                <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default promo;
