import React from "react";
import "./Produk2.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Bedimages from "./Bed";
import Studio4images from "./Studio4";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faLocationDot,
  faMedal,
  faN,
  faSwimmingPool,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {faBuilding} from "@fortawesome/free-solid-svg-icons/faBuilding";

const Produk2 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Cherrywa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285283066969&text=Halo%20Kak,%20Saya%20lihat%20iklan%20diwebsite,%20apa%20ada%20room%20available?%20(Barclay%20North%202)%20https://sewa-apartemen-lagoon.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const WTwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285283066969&text=Halo%20Kak,%20Saya%20lihat%20iklan%20diwebsite,%20apa%20ada%20room%20available?%20(Emerald%20North%201)%20https://sewa-apartemen-lagoon.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="container-card3">
        <div className="card">
          <Slider {...settings}>
            {Studio4images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Barclay North 2</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#ad7e36"
                icon={faMedal}
              />
              <div className="namalokasi">Studio</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faN} />
            <span> Netflix</span>
            <FontAwesomeIcon color="#091c34" icon={faWifi} />
            <span> Wifi</span>
            <FontAwesomeIcon color="#091c34" icon={faSwimmingPool} />
            <span> Pool</span>
            <FontAwesomeIcon color="#091c34" icon={faBuilding} />
            <span> City View</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">100rb</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Cherrywa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Bedimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Emerald North 1</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#ad7e36"
                icon={faMedal}
              />
              <div className="namalokasi">1 Bedroom</div>
            </div>
          </div>

          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faN} />
            <span> Netflix</span>
            <FontAwesomeIcon color="#091c34" icon={faWifi} />
            <span> Wifi</span>
            <FontAwesomeIcon color="#091c34" icon={faSwimmingPool} />
            <span> Pool</span>
            <FontAwesomeIcon color="#091c34" icon={faBuilding} />
            <span> City View</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">100rb</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={WTwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk2;
